import { Routes, Route } from "react-router-dom";
import GoogleFormsNotion from "../Pages/IntegrationDomain/GoogleForms-Notion/RoutingPage";
import IntegrationsPage from "../Pages/IntegrationDomain/Integrations";

const IntegrationsDomain = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IntegrationsPage />} />
        <Route path="/googleforms-notion/*" element={<GoogleFormsNotion />} />
        <Route path="*" element={<IntegrationsPage />} />
      </Routes>
    </div>
  );
};

export default IntegrationsDomain;

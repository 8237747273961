const HomePage = () => {
  return (
    <div>
      <h1>Google Forms and Notion integration</h1>

      <p>
        An integration that allows people to import data into notion with a
        Google Form
      </p>
    </div>
  );
};

export default HomePage;

import { Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import SuccessPage from "./SuccessPage";

const GoogleFormsNotion = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/successfullyInstalled" element={<SuccessPage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
};

export default GoogleFormsNotion;

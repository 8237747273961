import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/HomeDomain/home";

const HomeDomain = ({ DOMAIN_NAME }) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage DOMAIN_NAME={DOMAIN_NAME} />} />
        <Route path="/test" element={<div>test</div>} />
        <Route path="*" element={<HomePage DOMAIN_NAME={DOMAIN_NAME} />} />
      </Routes>
    </div>
  );
};

export default HomeDomain;

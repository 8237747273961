import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div>
      <h1>Success Page</h1>

      <p>Thanks for installing the Google Forms -> notion integration!</p>

      <p>This is a completely open sourced integration - Enjoy!</p>

      <div style={{ marginBottom: "20px" }}>
        <a href="">Github</a>
      </div>
      <Link to="/googleforms-notion">Go To Main Page</Link>
    </div>
  );
};

export default SuccessPage;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeDomain from "./Subdomains/HomeDomain";
import IntegrationsDomain from "./Subdomains/IntegrationDomain";

const DOMAIN_NAME = "matthewsworkspace.com";

// xcxc for testing only - remove before deploy
// const DOMAIN_NAME = "localhost:3000";

const get_route_for_subdomain = (subdomain) => {
  switch (subdomain) {
    case "integrations":
      return <Route path="*" element={<IntegrationsDomain />} />;
    default:
      return (
        <Route path="*" element={<HomeDomain DOMAIN_NAME={DOMAIN_NAME} />} />
      );
  }
};

function App() {
  const subdomain_end_index = window.location.host.indexOf(DOMAIN_NAME) - 1;
  const subdomain = window.location.host.substring(0, subdomain_end_index);

  return (
    <BrowserRouter>
      <Routes>{get_route_for_subdomain(subdomain)}</Routes>
    </BrowserRouter>
  );
}

export default App;

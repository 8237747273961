const HomePage = ({ DOMAIN_NAME }) => {
  return (
    <div>
      <h1>Welcome!</h1>
      <p>This is a repository for my projects. Please Enjoy!</p>

      <h3>Projects</h3>
      <div>
        {console.log(`integrations.${DOMAIN_NAME}`)}
        <a href={`http://integrations.${DOMAIN_NAME}`} target="_blank">
          Integrations
        </a>
      </div>
    </div>
  );
};

export default HomePage;
